/* Flexbox Helpers
TODO: Deprecate these utility classes, they are an ugly approximation of Tailwind*/
.d--f {
    display: flex;
}

/* Justify content */
.jc--fe {
    justify-content: flex-end;
}
.jc--fs {
    justify-content: flex-start;
} /* Default */
.jc--c {
    justify-content: center;
}
.jc--sa {
    justify-content: space-around;
}
.jc--sb {
    justify-content: space-between;
}

/* Align items */
.ai--fs {
    align-items: flex-start;
}
.ai--c {
    align-items: center;
}
.ai--b {
    align-items: baseline;
}
